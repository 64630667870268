<template>
  <div class="contentUs">
    <div class="cuBox">
      <ul>
        <li>
          <img src="../../static/common/email.png" alt="">
          <p>
            <span>客服邮箱</span>
            <span>cs@rlcvipltd.net</span>
          </p>
        </li>
        <li>
          <img src="../../static/common/localhost.png" alt="">
          <p>
            <span>公司地址</span>
            <span>香港九龙尖沙咀弥敦道88号安乐大厦10楼F座</span>
          </p>
        </li>
        <li>
          <img src="../../static/common/phone.png" alt="">
          <p>
            <span>联系电话</span>
            <span>(852) 23919622</span>
          </p>
        </li>
      </ul>
      <div class="levelLine"></div>
      <div class="tips">
        <ul>
          <li>温馨提示：市场有风险，投资需谨慎，最终受益以实际为准</li>
          <li>应用名称：红狮启富</li>
          <li @click="checkPower(1)" style="cursor: pointer;">应用权限：查看权限</li>
          <li @click="checkPower(3)" style="cursor: pointer;">功能描述：查看功能</li>
          <li>更新时间：2024年09月02日</li>
        </ul>
        <ul>
          <li>APP开发者：香港红狮集团有限公司</li>
          <li>应用版本：5.0.0</li>
          <li @click="checkPower(2)" style="cursor: pointer;">隐私权限：查看权限</li>
          <li>香港红狮集团有限公司是香港金银业贸易场AA类行员，行员编号：081</li>
        </ul>
      </div>
      <div class="copyRight">COPYRIGHT(©) 2023 红狮集团</div>
    </div>
  </div>
</template>

<script>
import router from '../router';

export default {
  name:'Contactus',
  data(){
    return{
      
    }
  },
  methods:{
    checkPower(attr){
      if(attr == 1){
        this.$router.push({
          name:'Application'
        })
        // window.open('https://sem.rlclead.com/agreement/permission');
      }else if(attr == 2){
        // window.open('https://sem.rlclead.com/agreement/privacy');
        this.$router.push({
          name:'Privacy'
        })
      }else{
        this.$router.push({
          name:'Functiong'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .contentUs{
    width: 100%;
    background-color: #121212;
    color: #616161;
    .cuBox{
      width: 1327px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-top: 30px;
      ul{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 80px;
        li{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            color: #ffffff;
            span{
              line-height: 30px;
            }
            span:nth-child(1){
              font-weight: 600;
              font-size: 14px;
            }
            span:nth-child(2){
              font-size: 13px;
            }
          }
        }
      }
      .levelLine{
        width: 100%;
        height: 1px;
        background-color: #393a3c;
        margin: 15px auto 20px;
      }
      .tips{
        width: 100%;
        box-sizing: border-box;
        padding: 0 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0;
          li{
            line-height: 35px;
            font-size: 14px;
          }
        }
      }
      .copyRight{
        text-align: center;
        font-size: 13px;
        margin: 15px 0 30px 0;
      }
    }
  }
</style>